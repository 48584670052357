<template>
  <v-app class="app">
    <notification-view />
    <layout-chat v-if="isMobileAndChatSelected" />
    <layout-header v-else-if="isMobile" class="main__layout-header" has-profile @update:is-trim="updateIsTrim" />
    <layout-sidebar v-else :routes="routes" has-profile></layout-sidebar>
    <v-main>
      <v-container
        class="main__container pt-0 pt-md-16 px-2 pb-0"
        :class="isChatSelected || isClientChat ? 'main__container--chat-selected' : 'pt-4'"
      >
        <error-page v-if="httpErrorCode" :code="httpErrorCode" />
        <router-view v-else />
      </v-container>
    </v-main>

    <layout-footer v-if="!isMobileAndChatSelected && !isClientChat" :routes="footerRoutes" has-profile />
    <mobile-navigation v-if="isMobile" :routes="mobileRoutes" />
  </v-app>
</template>

<script>
// Config
import { defineRulesFor } from '@/config/ability';

// Constants
import * as routes from '@/constants/routes';
import { READ } from '@/constants/actions';
import * as subjects from '@/constants/subjects';
import { SET_CLIENT_ROOM, SET_EMPLOYEE_ROOM } from '@/store/modules/chat/actions/types';
import { SET_UNREAD_COUNT } from '@/store/modules/chat/mutations/types';
import { SET_ONLINE, SET_OFFLINE, SET_CONNECTED } from '@/store/modules/common/mutations/types';
import { CLIENT, MANAGEMENT_COMPANY_EMPLOYEE } from '@/constants/roles';
import {
  CLIENTS_ROOMS,
  CLIENTS_GET_ROOMS,
  EMPLOYEES_ROOMS,
  EMPLOYEES_GET_ROOMS,
  EMPLOYEES_UNREAD_CHATS_COUNT,
  EMPLOYEES_GET_UNREAD_CHATS_COUNT,
} from '@/constants/chatEvents';
import { RIGHTS } from '@/store/modules/user/types';
import { CHAT } from '@/constants/routes';

// Components
import LayoutHeader from '@/components/layout/Header/index.vue';
import LayoutSidebar from '@/components/layout/Sidebar/index.vue';
import LayoutChat from '@/components/layout/Chat/index.vue';
import MobileNavigation from '@/components/layout/MobileNavigation.vue';
import LayoutFooter from '@/components/layout/Footer.vue';
import NotificationView from '@/components/NotificationView.vue';
import ErrorPage from '@/components/Error.vue';

// Icons
import IconAnnouncement from '@/components/Icons/Announcement.vue';
import IconChat from '@/components/Icons/Chat.vue';
import IconClients from '@/components/Icons/Clients.vue';
import IconContracts from '@/components/Icons/Contracts.vue';
import IconContractors from '@/components/Icons/Contractors.vue';
import IconDashboard from '@/components/Icons/Dasboard.vue';
import IconEmployees from '@/components/Icons/Employees.vue';
import IconInvoices from '@/components/Icons/Invoices.vue';
import IconProjects from '@/components/Icons/Projects.vue';
import IconAssets from '@/components/Icons/Assets.vue';
import IconTasks from '@/components/Icons/Tasks.vue';
import IconServices from '@/components/Icons/Services.vue';

// Services
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import chatService from '@/services/chat';
import errorService from '@/services/error';
import paymentsService from '@/services/payments';

const UPDATE_INTERVAL = 5000;
const EMPLOYEES_UPDATE_INTERVAL = 7000;
const EMPLOYEES_UPDATE_CHAT_COUNT = 10000;
const RECONNECT_TIME = 30000;

export default {
  name: 'MainLayout',

  components: { LayoutHeader, LayoutSidebar, LayoutChat, MobileNavigation, LayoutFooter, NotificationView, ErrorPage },

  props: {
    routeName: { type: String, required: true },
    fullPath: { type: String, required: true },
  },

  inject: ['media'],

  data() {
    return {
      fetchIntervalId: null,
      windowHeight: window.innerHeight,
      reconnectSnackbarIsShow: false,
      reconnectTime: null,
      httpErrorCode: undefined,
      newInvoicesCount: 0,
      trimMenu: false,
    };
  },

  computed: {
    ...mapState({
      unreadCount: state => state.chat.unreadCount,
      userRole: state => state.user.role,
    }),

    ...mapGetters('user', {
      userRights: RIGHTS,
    }),

    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },

    isChatPage() {
      return this.$route.name === routes.CHAT;
    },

    isChatSelected() {
      return this.isChatPage && !!this.$route.query.id;
    },

    isClientChat() {
      return this.userRole === CLIENT && this.isChatPage;
    },

    isMobileAndChatSelected() {
      return this.isMobile && this.isChatSelected && !this.isClientChat;
    },

    isEmployee() {
      return this.$store.state.user.role === MANAGEMENT_COMPANY_EMPLOYEE;
    },

    cardsRoute() {
      return {
        id: 0,
        to: { name: routes.CARDS },
        text: this.$t('navigation.cards'),
        icon: 'mdi-credit-card',
        available: this.$can(READ, subjects.CARDS),
        exact: true,
      };
    },

    invoiceRoute() {
      return {
        id: 1,
        to: { name: routes.INVOICES_PARENT },
        text: this.$t('navigation.invoice'),
        icon: IconInvoices,
        badge: this.newInvoicesCount,
        available: this.$can(READ, subjects.INVOICES),
      };
    },

    projectRoute() {
      return {
        id: 2,
        to: { name: routes.PROJECTS_PARENT },
        text: this.$t('navigation.projects'),
        icon: IconProjects,
        available: this.$can(READ, subjects.PROJECTS),
      };
    },

    clientRoute() {
      return {
        id: 3,
        to: { name: routes.CLIENTS_PARENT, query: { page: 1 } },
        text: this.$t('navigation.clients'),
        icon: IconClients,
        available: this.$can(READ, subjects.CLIENTS),
      };
    },

    contractorRoute() {
      return {
        id: 4,
        to: { name: routes.CONTRACTORS_PARENT, query: { page: 1 } },
        text: this.$t('navigation.contractors'),
        icon: IconContractors,
        available: this.$can(READ, subjects.CONTRACTORS),
      };
    },

    employeeAnnouncementRoute() {
      return {
        id: 6,
        to: { name: routes.ANNOUNCEMENTS_PARENT },
        text: this.$t('navigation.announcements'),
        icon: IconAnnouncement,
        available: this.$can(READ, subjects.ANNOUNCEMENTS) && this.isEmployee,
      };
    },

    chatRoute() {
      return {
        id: 5,
        to: { name: routes.CHAT },
        text: this.$t('navigation.chat'),
        icon: IconChat,
        badge: this.unreadCount,
        available: this.$can(READ, subjects.CHAT),
      };
    },

    clientAnnouncementRoute() {
      return {
        id: 7,
        to: { name: routes.ANNOUNCEMENTS_PARENT },
        text: this.$t('navigation.announcements'),
        icon: IconAnnouncement,
        available: !this.isEmployee && this.$can(READ, subjects.ANNOUNCEMENTS),
      };
    },

    dashboardRoute() {
      return {
        id: 9,
        to: { name: routes.DASHBOARD },
        text: this.$t('navigation.dashboard'),
        available: this.$can(READ, subjects.DASHBOARD) && this.isEmployee,
        icon: IconDashboard,
        exact: true,
      };
    },

    employeesRoute() {
      return {
        id: 10,
        to: { name: routes.EMPLOYEES_PARENT },
        text: this.$t('navigation.employees'),
        available: this.$can(READ, subjects.EMPLOYEES) && this.isEmployee,
        icon: IconEmployees,
        exact: true,
      };
    },

    contractsRoute() {
      return {
        id: 11,
        to: { name: routes.CONTRACTS_PARENT },
        text: this.$t('navigation.contracts'),
        available: this.$can(READ, subjects.CONTRACTS) && this.isEmployee,
        icon: IconContracts,
        exact: true,
      };
    },

    taskRoute() {
      return {
        id: 12,
        to: { name: routes.TASKS_PARENT },
        text: this.$t('navigation.task'),
        available: this.$can(READ, subjects.ISSUES) && this.isEmployee,
        icon: IconTasks,
        exact: true,
      };
    },

    servicesRoute() {
      return {
        id: 13,
        to: { name: routes.SERVICES_PARENT },
        text: this.$t('navigation.services'),
        available: this.$can(READ, subjects.SERVICES) && this.isEmployee,
        icon: IconServices,
        exact: true,
      };
    },

    assetRoute() {
      return {
        id: 14,
        to: { name: routes.ASSETS_PARENT },
        text: 'Assets',
        icon: IconAssets,
        available: this.$can(READ, subjects.ASSETS),
      };
    },

    routes() {
      if (this.trimMenu && this.isEmployee) {
        return [
          this.dashboardRoute,
          this.employeesRoute,
          this.invoiceRoute,
          this.assetRoute,
          this.chatRoute,
          this.cardsRoute,
          this.projectRoute,
          this.clientRoute,
          this.contractsRoute,
          this.taskRoute,
          this.servicesRoute,
          this.contractorRoute,
          this.employeeAnnouncementRoute,
          this.clientAnnouncementRoute,
        ].filter(route => route.available !== false);
      }

      return [
        this.dashboardRoute,
        this.employeesRoute,
        this.cardsRoute,
        this.invoiceRoute,
        this.projectRoute,
        this.clientRoute,
        this.contractsRoute,
        this.taskRoute,
        this.servicesRoute,
        this.contractorRoute,
        this.employeeAnnouncementRoute,
        this.assetRoute,
        this.chatRoute,
        this.clientAnnouncementRoute,
      ].filter(route => route.available !== false);
    },

    mobileRoutes() {
      return [
        this.dashboardRoute,
        this.employeesRoute,
        this.clientAnnouncementRoute,
        this.invoiceRoute,
        this.cardsRoute,
        this.chatRoute,
        this.projectRoute,
        this.assetRoute,
        this.clientRoute,
        this.contractsRoute,
        this.taskRoute,
        this.servicesRoute,
        this.contractorRoute,
        this.employeeAnnouncementRoute,
      ].filter(route => route.available !== false);
    },

    footerRoutes() {
      return [
        ...this.routes,
        {
          id: 8,
          to: { name: routes.PROFILE },
          text: this.$t('profile.profile'),
          available: this.$can(READ, subjects.PROFILE),
        },
      ];
    },

    formatedTime() {
      return Math.round(this.reconnectTime / 1000);
    },
  },

  watch: {
    routeName() {
      if (this.$can(READ, subjects.NEW_INVOICES_COUNT)) {
        this.fetchInvoicesCount();
      }

      if (this.isEmployee) {
        this.setEmployeeListener();
      }
    },

    fullPath() {
      this.httpErrorCode = undefined;
    },

    userRights: {
      handler(newValue) {
        this.$ability.update(defineRulesFor(this.userRole, newValue));
      },
      immediate: true,
    },
  },

  destroyed() {
    errorService.unsubscribe(this.handleError);
    chatService.socket.removeListener(EMPLOYEES_ROOMS, this.setLocalEmployeeRoom);
    chatService.socket.removeListener(CLIENTS_ROOMS, this.setLocalClientRoom);
    chatService.socket.removeListener(EMPLOYEES_UNREAD_CHATS_COUNT, this.setLocalUnreadCount);

    this.clearInterval();
  },

  mounted() {
    if (this.$can(READ, subjects.NEW_INVOICES_COUNT)) {
      this.fetchInvoicesCount();
    }
  },

  beforeMount() {
    errorService.subscribe(this.handleError);
  },

  async beforeCreate() {
    if (this.$options.intervalInstance) {
      this.clearInterval();
    }

    await chatService.connect().catch(() => {
      this.reconnectSnackbarIsShow = true;
    });

    this.setConnect(true);

    if (this.isEmployee) {
      this.setEmployeeListener();

      return;
    }

    chatService.socket.on(CLIENTS_ROOMS, this.setLocalClientRoom);
    chatService.socket.send(CLIENTS_GET_ROOMS);

    this.$options.intervalInstance = setInterval(() => {
      chatService.socket.send(CLIENTS_GET_ROOMS);
    }, UPDATE_INTERVAL);
  },

  methods: {
    ...mapActions({
      setClientRoom: SET_CLIENT_ROOM,
      setEmployeeRoom: SET_EMPLOYEE_ROOM,
    }),

    ...mapMutations('common', {
      setOnline: SET_ONLINE,
      setOffline: SET_OFFLINE,
      setConnect: SET_CONNECTED,
    }),

    ...mapMutations({
      setUnreadCount: SET_UNREAD_COUNT,
    }),

    setEmployeeListener() {
      if (this.$options.intervalInstance) {
        clearInterval(this.$options.intervalInstance);
      }

      chatService.socket.removeListener(EMPLOYEES_ROOMS, this.setLocalEmployeeRoom);
      chatService.socket.removeListener(EMPLOYEES_UNREAD_CHATS_COUNT, this.setLocalUnreadCount);

      if (this.$route.name === CHAT) {
        chatService.socket.on(EMPLOYEES_ROOMS, this.setLocalEmployeeRoom);
        chatService.socket.send(EMPLOYEES_GET_ROOMS);

        this.$options.intervalInstance = setInterval(() => {
          chatService.socket.send(EMPLOYEES_GET_ROOMS);
        }, EMPLOYEES_UPDATE_INTERVAL);

        return;
      }

      chatService.socket.on(EMPLOYEES_UNREAD_CHATS_COUNT, this.setLocalUnreadCount);
      chatService.socket.send(EMPLOYEES_GET_UNREAD_CHATS_COUNT);

      this.$options.intervalInstance = setInterval(() => {
        chatService.socket.send(EMPLOYEES_GET_UNREAD_CHATS_COUNT);
      }, EMPLOYEES_UPDATE_CHAT_COUNT);
    },

    setLocalUnreadCount(data) {
      this.setUnreadCount(data.unreadChatCount);
    },

    updateIsTrim(value) {
      this.trimMenu = value;
    },

    fetchInvoicesCount() {
      paymentsService.getNewInvoicesCount().then(({ count }) => {
        this.newInvoicesCount = count;
      });
    },

    connectToWs() {
      this.reconnectSnackbarIsShow = false;

      if (this.$options.reconnectTimerInstance) {
        clearInterval(this.$options.reconnectTimerInstance);
        this.$options.reconnectTimerInstance = null;
        this.reconnectTime = RECONNECT_TIME;
      }

      return chatService.connect().catch(() => {
        this.waitForReconnection();
      });
    },

    waitForReconnection() {
      if (this.$options.reconnectTimerInstance) {
        clearInterval(this.$options.reconnectTimerInstance);
        this.$options.reconnectTimerInstance = null;
      }

      this.reconnectSnackbarIsShow = true;
      this.reconnectTime = RECONNECT_TIME;

      let lastTime = Date.now();

      this.$options.reconnectTimerInstance = setInterval(() => {
        const currentTime = Date.now();
        this.reconnectTime -= currentTime - lastTime;
        lastTime = currentTime;

        if (this.reconnectTime <= 0) {
          this.reconnectTime = 0;
          clearInterval(this.$options.reconnectTimerInstance);

          this.$options.reconnectTimerInstance = null;
        }
      }, 1000);
    },

    async setLocalClientRoom(room) {
      if (room.rooms.length === 0) {
        const newRoom = await chatService.getClientRoom();
        this.setClientRoom({ rooms: [newRoom] });

        return;
      }

      this.setClientRoom(room);
    },

    setLocalEmployeeRoom(room) {
      this.setEmployeeRoom(room);
    },

    clearInterval() {
      clearInterval(this.$options.intervalInstance);
      this.$options.intervalInstance = null;
    },

    handleError(code) {
      this.httpErrorCode = code;
    },
  },

  intervalInstance: null,
  reconnectTimerInstance: null,
};
</script>

<style lang="scss">
.app {
  .main {
    &__container {
      height: 100%;

      @media (min-width: map.get($--screens, 'sm')) {
        max-width: initial;
      }

      &--chat-selected {
        @media (max-width: map.get($--screens, 'sm')) {
          height: calc(100vh - #{$--chat-mobile-input-padding});
        }
      }
    }

    &__layout-header,
    &__container {
      @media (min-width: map.get($--screens, 'sm')) {
        padding-left: 64px !important;
        padding-right: 64px !important;
      }

      @media (min-width: map.get($--screens, 'md')) {
        padding-left: 160px !important;
        padding-right: 160px !important;
      }
    }
  }
}
</style>
